import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import SEO from "../components/seo"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title='Home' description='Chai Chuah' />
    <div className="text-black pb-3">
      <div className="container py-5 py-lg-6 my-5 my-md-0 text-center">
        {/* <h1 className="display-3 pb-3"><img src={chai_text} alt="logo" /></h1> */}
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <p className="lead text-light">Former Director-General , New Zealand Ministry of Health</p>
          </div>
        </div>
      </div>
    </div>


    <div className="bg-white text-black pb-5">
        <div className="container py-2">
          <div className="row mt-n6 blog-list">

          {data.allStrapiArticle.edges.map(document => (
            <div key={document.node.id} className="col-md-6">
              <div className="card border-0 text-center shadow mb-5 pt-3">
                <h2 className="card-title h3 px-3">
                  <Link className="img-rising" to={`/blog/${document.node.slug}`}>{document.node.Title}</Link>
                </h2>
                <div className="small text-muted mb-3">{document.node.published_on || document.node.created_at}</div>
                <Link className="img-rising" to={`/blog/${document.node.slug}`}>
                  {<Img className="card-img-top" fluid={document.node.Image.childImageSharp ? document.node.Image.childImageSharp.fluid : ''} />}
                  {/* <img src="https://4.bp.blogspot.com/-OMvN26-Ests/V5pngiVhq-I/AAAAAAAAIkA/PvTLWtq44nQO0XJ4Etu-xQPhdmCTJPUcQCEw/s1600/lifestyle54-1200x800.jpg" /> */}
                </Link>
                <div className="card-body">
                  {/* <a href="/" className="d-block text-muted text-uppercase mb-2">Architecture</a> */}
                  <p className="card-text text-muted">{document.node.excerpt}</p>
                </div>
              </div>              
            </div>
          ))}

        </div>
      </div>
    </div>

    <div className="bg-white text-black pb-5 text-center">
      <Link to="/posts/2" className="btn btn-outline-dark">Older posts</Link>
    </div>


    <div className="bg-primary text-dark">
      <div className="container py-6">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-6 border-lg-right border-dark pr-lg-5">
            <h3 className="display-4 mb-4 text-lg-right">Would you like to see more?</h3>
          </div>
          <div className="col-lg-6 pl-lg-5">
            <p className="lead">Learn more about Chai Chuah.</p>
            <Link to="/about" className="btn btn-outline-dark">About</Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
    allStrapiArticle (
      sort: {
        fields: [published_on]
        order: DESC
      }
      limit: 4
      skip: 0
    ) {
      edges {
        node {
          id
          Title
          excerpt
          slug
          created_at(formatString: "DD MMMM YYYY")
          published_on(formatString: "DD MMMM YYYY")
          Image {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`